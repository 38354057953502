<template>
  <modal
    name="taskThreadsModal"
    transition="pop-out"
    class="task-threads-modal"
    :width="650"
    :focus-trap="true"
    :height="550"
  >
    <div class="position-relative modal-outer-container">
      <div class="modal-header border-bottom justify-content-center">
        <div class="ellipsis pr-5">{{ task.name }}</div>
        <button class="btn border-0 close-btn" @click="closeModal">
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <div class="body-inner-container">
        <div class="card-body">
          <h2 class="text-left pt-2 pb-2 mb-0 threads-title">Threads</h2>
          <div class="chat-container" v-if="(task.replies || []).length">
            <ul class="chat p-0">
              <li
                class="message"
                v-for="reply in task.replies"
                :key="reply.id"
                :id="`reply_message_${reply.id}`"
                :class="reply.hidden ? 'hidden-message' : ''"
              >
                <div class="thread-info">
                  <p class="font-weight-bold mb-0">{{ reply.user.name }}</p>
                  <p class="small mb-0" style="opacity: 0.72">
                    {{ prettyDate(reply.created_at) }}
                  </p>
                </div>
                <div
                  class="
                    message-body
                    d-flex
                    align-items-start
                    justify-content-start
                    mb-2
                  "
                >
                  <img
                    class="logo mr-3"
                    :src="reply.user.profile_picture || profileImagePlaceholder"
                  />
                  <div
                    class="message-content"
                    v-html="
                      $options.filters.nl2br(
                        $options.filters.slash2hr(reply.description)
                      )
                    "
                  ></div>
                </div>

                <div
                  class="comments-chat-container"
                  v-if="(reply.comments || []).length"
                >
                  <h3 class="text-left pt-3">Comments</h3>
                  <ul class="comment-chat p-0">
                    <li
                      class="comment-message"
                      v-for="comment in reply.comments"
                      :key="comment.id"
                      :id="`reply_message_${comment.id}`"
                      :class="comment.hidden ? 'hidden-message' : ''"
                    >
                      <div class="comment-thread-info">
                        <p class="font-weight-bold mb-0">
                          {{ comment.user.name }}
                        </p>
                        <p class="small text-semi-muted mb-0">
                          {{ prettyDate(comment.created_at) }}
                        </p>
                      </div>
                      <hr class="m-0" />
                      <div
                        class="
                          comment-message-body
                          d-flex
                          align-items-start
                          justify-content-start
                          mb-2
                        "
                      >
                        <img
                          class="comment-logo mr-3"
                          :src="
                            comment.user.profile_picture ||
                            profileImagePlaceholder
                          "
                        />
                        <div
                          class="comment-message-content"
                          v-html="
                            $options.filters.nl2br(
                              $options.filters.slash2hr(comment.description)
                            )
                          "
                        ></div>
                      </div>
                    </li>
                  </ul>
                </div>
                <hr style="opacity: 0" v-if="!(reply.comments || []).length" />
              </li>
            </ul>
          </div>
          <alert
            class="my-2 text-left light-shadow"
            v-if="task.id && !(task.replies || []).length"
            >No Threads Found</alert
          >
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import helpers from "@/utils/helpers";

export default {
  name: "TaskThreadsModal",
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
    };
  },
  methods: {
    prettyDate: function (date) {
      if (!date) {
        return "";
      }
      return helpers.prettyDate(date);
    },
    closeModal: function () {
      this.$modal.hide("taskThreadsModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.task-threads-modal {
  .close-btn {
    position: absolute;
    right: 10px;
  }
  .card-body {
    height: 100%;
    overflow-y: auto;
  }
  .body-inner-container {
    height: calc(100% - 54px);
  }
}
</style>
<style lang="scss">
.task-threads-modal {
  &.v--modal-overlay {
    z-index: 1002;
  }

  .modal-outer-container {
    background-color: var(--body-bg-color);
  }
  .thread-info {
    background-color: rgba(37, 37, 37, 0.4);
    color: #ffffff;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 12px 24px;
  }

  .comment-thread-info {
    background-color: rgba(116, 116, 116, 0.8);
    color: #ffffff;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 12px 24px;
  }

  .threads-title {
    letter-spacing: 1px;
    font-weight: bold;
  }

  .chat-container {
    border-radius: 20px;
    padding-top: 16px;
    position: relative;
    width: 100%;

    .chat {
      list-style-type: none;

      .message {
        position: relative;
        margin-bottom: 30px;
        width: 100%;
        min-height: 100px;

        &.hidden-message {
          .thread-info,
          .logo,
          .message-content,
          .history-button-container {
            opacity: 0.8;
          }
        }

        .message-body {
          background-color: #ffffff;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 32px;
          border-bottom-left-radius: 32px;
          padding: 12px 24px;
          margin-top: -4px;
          .logo {
            border-radius: 50%;
            box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.3);
            object-fit: cover;
            width: 60px;
            height: 60px;

            @media (max-width: 768px) {
              width: 50px;
              height: 50px;
            }
          }
          .message-content {
            width: 100%;
            word-break: break-all;
          }
        }
      }
    }

    .text-semi-muted {
      color: #d6e0ef;
    }
  }

  .comments-chat-container {
    padding: 0 12px;
    position: relative;
    width: 100%;
    @media (max-width: 768px) {
      padding: 0 8px;
    }

    .comment-chat {
      list-style-type: none;
      margin-left: 50px;

      .comment-message {
        border: 1px solid transparent;
        position: relative;
        margin-bottom: 30px;
        width: 100%;
        min-height: 100px;

        &.selected-reply {
          animation: shadowBlink 1s 5;
          box-shadow: 0px 0px 13px 3px var(--theme-color);
        }

        &.hidden-message {
          .comment-thread-info,
          .comment-logo,
          .comment-message-content,
          .history-button-container {
            opacity: 0.8;
          }
        }

        .comment-message-body {
          background-color: #ffffff;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 32px;
          border-bottom-left-radius: 32px;
          padding: 12px 24px;
          margin-top: -4px;
          .comment-logo {
            border-radius: 50%;
            box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.3);
            object-fit: cover;
            width: 60px;
            height: 60px;

            @media (max-width: 768px) {
              width: 50px;
              height: 50px;
            }
          }
          .comment-message-content {
            width: 100%;
            word-break: break-all;
          }
        }
      }
    }
  }
}
</style>
