var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"task-threads-modal",attrs:{"name":"taskThreadsModal","transition":"pop-out","width":650,"focus-trap":true,"height":550}},[_c('div',{staticClass:"position-relative modal-outer-container"},[_c('div',{staticClass:"modal-header border-bottom justify-content-center"},[_c('div',{staticClass:"ellipsis pr-5"},[_vm._v(_vm._s(_vm.task.name))]),_c('button',{staticClass:"btn border-0 close-btn",on:{"click":_vm.closeModal}},[_c('i',{staticClass:"fas fa-times fa-lg"})])]),_c('div',{staticClass:"body-inner-container"},[_c('div',{staticClass:"card-body"},[_c('h2',{staticClass:"text-left pt-2 pb-2 mb-0 threads-title"},[_vm._v("Threads")]),((_vm.task.replies || []).length)?_c('div',{staticClass:"chat-container"},[_c('ul',{staticClass:"chat p-0"},_vm._l((_vm.task.replies),function(reply){return _c('li',{key:reply.id,staticClass:"message",class:reply.hidden ? 'hidden-message' : '',attrs:{"id":`reply_message_${reply.id}`}},[_c('div',{staticClass:"thread-info"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(reply.user.name))]),_c('p',{staticClass:"small mb-0",staticStyle:{"opacity":"0.72"}},[_vm._v(" "+_vm._s(_vm.prettyDate(reply.created_at))+" ")])]),_c('div',{staticClass:"message-body d-flex align-items-start justify-content-start mb-2"},[_c('img',{staticClass:"logo mr-3",attrs:{"src":reply.user.profile_picture || _vm.profileImagePlaceholder}}),_c('div',{staticClass:"message-content",domProps:{"innerHTML":_vm._s(
                    _vm.$options.filters.nl2br(
                      _vm.$options.filters.slash2hr(reply.description)
                    )
                  )}})]),((reply.comments || []).length)?_c('div',{staticClass:"comments-chat-container"},[_c('h3',{staticClass:"text-left pt-3"},[_vm._v("Comments")]),_c('ul',{staticClass:"comment-chat p-0"},_vm._l((reply.comments),function(comment){return _c('li',{key:comment.id,staticClass:"comment-message",class:comment.hidden ? 'hidden-message' : '',attrs:{"id":`reply_message_${comment.id}`}},[_c('div',{staticClass:"comment-thread-info"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(comment.user.name)+" ")]),_c('p',{staticClass:"small text-semi-muted mb-0"},[_vm._v(" "+_vm._s(_vm.prettyDate(comment.created_at))+" ")])]),_c('hr',{staticClass:"m-0"}),_c('div',{staticClass:"comment-message-body d-flex align-items-start justify-content-start mb-2"},[_c('img',{staticClass:"comment-logo mr-3",attrs:{"src":comment.user.profile_picture ||
                          _vm.profileImagePlaceholder}}),_c('div',{staticClass:"comment-message-content",domProps:{"innerHTML":_vm._s(
                          _vm.$options.filters.nl2br(
                            _vm.$options.filters.slash2hr(comment.description)
                          )
                        )}})])])}),0)]):_vm._e(),(!(reply.comments || []).length)?_c('hr',{staticStyle:{"opacity":"0"}}):_vm._e()])}),0)]):_vm._e(),(_vm.task.id && !(_vm.task.replies || []).length)?_c('alert',{staticClass:"my-2 text-left light-shadow"},[_vm._v("No Threads Found")]):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }